.org-table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
  }
  
  .org-table td {
    position: relative;
    vertical-align: top;
    padding: 0 0 50px 0;
    text-align: center;
  }
  
  .org-node {
    position: relative;
    display: inline-block;
    margin: 0 1em;
    box-sizing: border-box;
  }
  
  .org-node .org-container {
    position: relative;
    display: inline-block;
    z-index: 2;
    width: 100%;
    min-width: 150px;
  }
  
  .org-title {
    font-weight: 700;
    width: 100%;
    border: 1px solid #00000000;
    background-color: var(--chakra-colors-chakra-body-bg);
    border-color: var(--chakra-colors-chakra-border);
    border-radius: 4px;
    padding: 15px 5px;
    white-space: pre;
  }
  
  .org-content {
    margin-top: 3px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    white-space: pre;
    text-align: left;
  }
  
  .org-content .org-content-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #e7e7e7;
  }
  
  .org-content .org-content-item:last-child {
    border-bottom: none;
  }
  
  .org-content-item .item-box {
    flex: 1;
  }
  
  .org-content-item .item-box .item-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .org-content-item .item-box .item-add {
    margin: 0;
    font-size: 0.8rem;
  }
  
  .org-content-item .avat {
    display: block;
    width: 3em;
    height: 3em;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 2em;
    margin-left: 5px;
  }
  
  .org-content-item .avat img {
    width: 100%;
    height: 100%;
  }
  
  .org-child-level::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    height: 15px;
    border-left: 2px solid #ccc;
    transform: translate3d(-1px, 0, 0);
  }
  
  .org-child-level::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    border-top: 2px solid #ccc;
  }
  
  .org-child-level:first-child:before,
  .org-child-level:last-child:before {
    display: none;
  }
  
  .org-child-level:first-child:after {
    left: 50%;
    height: 13px;
    border: 2px solid;
    border-color: #ccc transparent transparent #ccc;
    transform: translate3d(1px, 0, 0);
  }
  
  .org-child-level:last-child:after {
    right: 50%;
    height: 13px;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    transform: translate3d(-1px, 0, 0);
  }
  
  .org-child-level:first-child.org-child-level:last-child::after {
    left: auto;
    border-radius: 0;
    border-color: transparent #ccc transparent transparent;
    transform: translate3d(1px, 0, 0);
  }
  
  .org-extend .org-extend-arrow:before {
    transform: rotateZ(-45deg);
  }
  
  .org-extend::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 13px;
    height: 17px;
    border-left: 2px solid #ccc;
  }
  
  .org-extend-arrow {
    position: absolute;
    left: 50.2%;
    bottom: 22px;
    width: 10px;
    height: 10px;
    padding: 10px;
    transform: translate3d(-15px, 0, 0);
    cursor: pointer;
  }
  
  .org-extend-arrow:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    transform: rotateZ(135deg);
    transform-origin: 50% 50% 0;
    transition: transform ease 300ms;
  }
  
  .org-extend-arrow:hover:before {
    border-color: #333 #333 transparent transparent;
  }